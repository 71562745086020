
import { defineComponent, ref, onMounted, computed } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRoute } from "vue-router";
import moment from "moment";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import store from "@/store";
import { addDesign, getDesignById, designObjClone } from "@/core/data/design";
import PartyMultiselect from "@/components/all-multiselect/party-multiselect.vue";
import { getError, getSuccess } from "@/core/helpers/utils";
import { PERMISSION_MODULE_NAME } from "@/core/data/permission";
import { DesignMaterial, getDesignMaterials } from "@/core/data/designMaterial";
import SeasonMultiselect from "@/components/all-multiselect/season-multiselect.vue";
export default defineComponent({
  name: "purchase-request-form",
  components: {
    Datepicker,
    PartyMultiselect,
    SeasonMultiselect,
  },
  setup() {
    const route = useRoute();
    const formRef = ref<null | HTMLFormElement>(null);
    const totalCost = ref<any>(0);
    const totalCuttingTime = ref<any>(0);
    const totalCuttingCost = ref<any>(0);
    const loading = ref<boolean>(false);
    const tab2 = ref<any>(null);
    const designMaterialList = ref<any>([]);
    const file = ref<any>(null);
    const multiForm = ref<any>({});
    const fileError = ref<any>("");
    const po_id = ref<any>("");
    const purchase_order = ref<any>({});
    const selectedTab = ref<any>("Details");
    const isEdit = ref<boolean>(true);
    const isAdd = ref<boolean>(true);
    const universityList = ref<Array<any>>([]);
    const formData = ref(designObjClone());
    const rules = ref({
      code: [
        {
          required: true,
          message: "Code is required",
          trigger: "change",
        },
      ],
    });
    const textAreaWordCount = ref<number>(0);
    onMounted(() => {
      formData.value = designObjClone();
      console.log("route.params.id", route.params.id);
      console.log(route.query.feeId);
      if (route.params.id) {
        isEdit.value = false;
        isAdd.value = false;
        getItemData();
      }
    });
    const setEdit = (status) => {
      isEdit.value = status;
    };
    const getItemData = () => {
      getDesignById(route.params.id).then((DesignStockItem: any) => {
        formData.value = {
          ...DesignStockItem,
        };
      });
      getDesignMaterials(1, "", "", route.params.id.toString(), 1000).then(
        (items: any) => {
          designMaterialList.value = items.data.map((designMaterialItem) => {
            designMaterialItem.totalCost = (
              designMaterialItem.width *
              designMaterialItem.height *
              designMaterialItem.material_data.rate
            ).toFixed(2);
            designMaterialItem.totalCuttingCost = (
              designMaterialItem.cuttingCost * designMaterialItem.cuttingTime
            ).toFixed(2);
            return designMaterialItem;
          });
          totalCost.value = designMaterialList.value
            .map((item) => parseFloat(item.totalCost))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2);
          totalCuttingCost.value = designMaterialList.value
            .map((item) => parseFloat(item.totalCuttingCost))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2);
          totalCuttingTime.value = designMaterialList.value
            .map((item) => parseFloat(item.cuttingTime))
            .reduce((partialSum, a) => partialSum + a, 0)
            .toFixed(2);
          console.log("items", items.data);
        }
      );
    };
    const grossCost = computed(() => {
      return (
        parseFloat(formData.value.peelingCost) +
        parseFloat(formData.value.fusingCost) +
        parseFloat(formData.value.transportCost) +
        parseFloat(formData.value.otherExpCost) +
        parseFloat(totalCost.value) +
        parseFloat(totalCuttingCost.value)
      );
    });
    const setSelectedTab = (name) => {
      selectedTab.value = name;
      getItemData();
    };
    const checkMaxSize = () => {
      fileError.value = "";
      if (file.value?.files[0]?.size > 1024 * 500) {
        fileError.value = "Max Size Should be 500kb";
      }
    };
    const submit = () => {
      if (!formRef.value) {
        return;
      }
      checkMaxSize();
      formRef.value.validate((valid) => {
        if (valid && !fileError.value) {
          loading.value = true;
          let reqObj: any = { ...formData.value };
          reqObj.date = moment(reqObj.date).format();
          if (hasCost.value) {
            reqObj.totalCuttingTime = totalCuttingTime.value;
            reqObj.totalCuttingCost = totalCuttingCost.value;
            reqObj.totalMaterialCost = totalCost.value;
          }
          if (!isAdmin.value) {
            delete reqObj.site;
          }
          console.log(file.value);
          addDesign(reqObj, file.value?.files, route.params.id)
            .then((responseData) => {
              loading.value = false;

              Swal.fire({
                text: getSuccess(
                  responseData,
                  "Form has been successfully submitted!"
                ),
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                setTimeout(() => {
                  if (!route.params.id) {
                    router.push({ name: "apps-design-listing" });
                  } else {
                    isEdit.value = false;
                    getItemData();
                  }
                }, 200);
              });
            })
            .catch((err) => {
              loading.value = false;
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };
    const handleInput = (e) => {
      console.log("input");
    };
    const universityListComputed = computed(() => {
      return universityList.value.map((item) => ({
        ...item,
      }));
    });
    const purchase_request = computed(() => {
      return route.params.id;
    });
    const userData = computed(() => {
      return store.getters.currentUser;
    });
    const isAdmin = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasCreate = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN].create
      );
    });
    const hasEdit = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN].update
      );
    });
    const hasDelete = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN].delete
      );
    });
    const hasStock = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN_STOCK].read
      );
    });
    const hasCost = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.DESIGN].show_cost
      );
    });
    const hasProductionCost = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].show_cost
      );
    });
    const hasMaterialCost = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL].show_cost
      );
    });
    const hasMaterial = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.MATERIAL].read
      );
    });
    const fieldList = computed(() => {
      let arr = [
        {
          name: "code",
          label: "Code",
          type: "text",
        },
        {
          name: "type",
          label: "Type",
          type: "text",
        },
        {
          name: "party",
          label: "Party",
          type: "PartyMultiselect",
          options: [],
        },
        {
          name: "season_id",
          label: "Season",
          type: "SeasonMultiselect",
        },
        {
          name: "remark",
          label: "Remark",
          type: "textarea",
        },
      ];
      if (hasCost.value) {
        arr.push({
          name: "designCost",
          label: "Design Cost",
          type: "text",
        });
      }
      if (hasProductionCost.value) {
        arr.push({
          name: "peelingCost",
          label: "Peeling Cost",
          type: "text",
        });
        arr.push({
          name: "fusingCost",
          label: "Fusing Cost",
          type: "text",
        });
        arr.push({
          name: "transportCost",
          label: "Transport Cost",
          type: "text",
        });
        arr.push({
          name: "otherExpCost",
          label: "Other Expenses Cost",
          type: "text",
        });
        arr.push({
          name: "finalCost",
          label: "Final Cost",
          type: "text",
        });
      }
      return arr;
    });

    const onClickBack = () => {
      router.back();
    };

    const onInputTextAreaValidation = (event) => {
      textAreaWordCount.value = parseInt(event.target.value.length);
    };

    return {
      isEdit,
      setEdit,
      formData,
      rules,
      formRef,
      submit,
      loading,
      file,
      moment,
      isAdd,
      handleInput,
      universityListComputed,
      isAdmin,
      po_id,
      purchase_request,
      setSelectedTab,
      selectedTab,
      purchase_order,
      tab2,
      multiForm,
      checkMaxSize,
      fileError,
      onClickBack,
      textAreaWordCount,
      onInputTextAreaValidation,
      fieldList,
      designMaterialList,
      totalCost,
      hasCost,
      hasMaterial,
      hasMaterialCost,
      totalCuttingCost,
      grossCost,
    };
  },
});
